import React from 'react';
import Footer from 'gatsby-theme-carbon/src/components/Footer';

const Content = ({ buildTime }) => (
  <>
    <p>
      Hello World!
      The <code>Content</code> component receives a <code>buildTime</code> prop
      that to display your site's build time: {buildTime}
    </p>
    <p>
      By importing the <strong>Footer</strong> component from
      gatsby-theme-carbon, we can supply our own props.
    </p>
    <p>
      The default export from a shadowed component will replace that component
      in the theme.
    </p>
    <p>
      <a href="https://www.gatsbyjs.org/docs/themes/api-reference/#component-shadowing">
        More about component shadowing
      </a>
    </p>
  </>
);

const myLogo = () => (
  <svg width="81px" height="32px" viewBox="0 0 81 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <title>Group</title>
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group">
          <rect id="Rectangle" fill="#FF0000" x="0" y="0" width="81" height="32" rx="6"></rect>
          <polygon id="Triangle" fill="#FFFFFF" transform="translate(14.000000, 16.000000) rotate(-90.000000) translate(-14.000000, -16.000000) " points="14 6 24 26 4 26"></polygon>
          <polygon id="Triangle" fill="#FFFFFF" transform="translate(66.000000, 16.000000) rotate(90.000000) translate(-66.000000, -16.000000) " points="66 6 76 26 56 26"></polygon>
          <circle id="Oval" stroke="#FFFFFF" stroke-width="5" fill="#000000" cx="40" cy="16" r="7.5"></circle>
      </g>
  </g>
</svg>


);

const links = {
  firstCol: [
    { href: 'https://startresponse.com/design', linkText: 'Shadowed link' },
    { href: 'https://startresponse.com/design', linkText: 'Shadowed link' },
    { href: 'https://startresponse.com/design', linkText: 'Shadowed link' },
  ],
  secondCol: [
    { href: 'https://startresponse.com/design', linkText: 'Shadowed link' },
    { href: 'https://startresponse.com/design', linkText: 'Shadowed link' },
    { href: 'https://startresponse.com/design', linkText: 'Shadowed link' },
    { href: 'https://startresponse.com/design', linkText: 'Shadowed link' },
  ],
  Logo: myLogo,
};



const CustomFooter = () => <Footer links={links} Content={Content} Logo={myLogo} />;

export default CustomFooter;
